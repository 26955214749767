/**
 * @typedef {import('../../api/loginservice.js').Argon2Params} Argon2Params
 */

const DB_NAME = 'keyDb';
const STORE_NAME = 'keyStore';
const ANONYMOUS_KEY_NAME = 'anonymous_key';

/**
 * @param {string} email 
 * @param {string} password 
 * @param {Argon2Params} param
 */
export async function deriveKekAndPasswordHash(email, password, param) {
    if(typeof email !== 'string' || !email.length
        || typeof password !== 'string' || !password.length) {
        console.error('Invalid arguments');
        throw new Error('Invalid arguments');
    }

    window.argon2WasmPath = '/node_modules/argon2-browser/dist/argon2.wasm';
    //await import("../../../../node_modules/argon2-browser/lib/argon2.js");
    await import("../../../../public/frontend/node_modules/argon2/argon2-bundled.min.js");
    
    let argon2Type;
    switch(param.type) {
        case 'ARGON2D':
            argon2Type = argon2.ArgonType.Argon2d;
        break;
        case 'ARGON2I':
            argon2Type = argon2.ArgonType.Argon2i;
        break;
        case 'ARGON2ID':
            argon2Type = argon2.ArgonType.Argon2id;
        break;
        default:
            console.error(`Invalid argon2 type ${param.type}`);
            throw new Error(`Invalid argon2 type ${param.type}`);
    }

    const hash = await argon2.hash({ 
        pass: password, 
        salt: email.toLowerCase(), 
        time: param.iterations, 
        mem: param.memoryInKb, 
        hashLen: 64, 
        parallelism: param.paralellism, 
        type: argon2Type
    });

    const lsPassword = btoa(String.fromCharCode.apply(null, hash.hash.slice(0, 32)));
	const kek = await crypto.subtle.importKey('raw', hash.hash.slice(32), {name: 'AES-KW'}, false, ['unwrapKey']);

    return {lsPassword: lsPassword, kek: kek};
}

/**
 * @param {string} wDekBase64 
 * @param {CryptoKey} kek 
 * @param {string} userId
 */
export async function unwrapDekAndStore(wDekBase64, kek, userId) {
    const wDek = Uint8Array.from(atob(wDekBase64), c => c.charCodeAt(0));

    const dek = await crypto.subtle.unwrapKey('raw', wDek, kek, { "name": "AES-KW" }, 
        { "name": "AES-GCM" }, false, ['decrypt', 'encrypt']);

    try {
        const db = await openDB();
        await saveCryptoKey(db, dek, userId);
    } catch(error) {
        console.error('Could not persist dek', error);
        throw error;
    }
}

/**
 * @param {string} userId 
 * @returns {Promise<CryptoKey | null>}
 */
export async function getAuthenticatedUserDek(userId) {
    try {
        const db = await openDB();
        return (await getDek(db, userId)) ?? null;
    } catch(error) {
        console.error('Could not get dek', error);
        throw error;
    }
}

/**
 * @returns {Promise<CryptoKey | null>}
 */
export async function getCurrentUserDek() {
    const loginHelper = await import('../loginhelper.js');
    if(!loginHelper.isLoggedIn()) {
        return null;
    }

    return await getAuthenticatedUserDek(loginHelper.getLoginDetails().userId);
}

export async function createAnonymousDek() {
    const key = await crypto.subtle.generateKey({name: 'AES-GCM', length: 256}, true, ['decrypt', 'encrypt']);
    try {
        const db = await openDB();
        await saveCryptoKey(db, key, ANONYMOUS_KEY_NAME);
        return key;
    } catch(error) {
        console.error('Could not get dek', error);
        throw error;
    }
}

/**
 * @returns {Promise<CryptoKey | null>}
 */
export async function getAnonymousUserDek() {
    try {
        const db = await openDB();
        return (await getDek(db, ANONYMOUS_KEY_NAME)) ?? null;
    } catch(error) {
        console.error('Could not get dek', error);
        throw error;
    }
}

/**
 * @returns {Promise<string | undefined>}
 */
export async function getAnonymousUserDekBase64() {
    const anonDek = await getAnonymousUserDek();
    return (anonDek instanceof CryptoKey)?
        btoa(String.fromCharCode(...new Uint8Array(await crypto.subtle.exportKey('raw', anonDek)))) : undefined;
}

export async function deleteAuthenticatedDek() {
    try {
        const db = await openDB();
        await deleteAllDeksExceptAnonymousDek(db);
    } catch(error) {
        console.error('Could not delete deks', error);
        throw error;
    }
}

async function getDek(db, keyEntryName) {
    return new Promise((resolve, reject) => {
        const transaction = db.transaction([STORE_NAME], 'readonly');
        const store = transaction.objectStore(STORE_NAME);
        const request = store.get(keyEntryName);

        request.onsuccess = (event) => {
            resolve(event.target.result);
        };

        request.onerror = (event) => {
            reject(event.target.error);
        };
    });
}

async function openDB() {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(DB_NAME);

        request.onupgradeneeded = (event) => {
            const db = event.target.result;
            db.createObjectStore(STORE_NAME);
        };

        request.onsuccess = (event) => {
            resolve(event.target.result);
        };

        request.onerror = (event) => {
            reject(event.target.error);
        };
    });
}

async function saveCryptoKey(db, key, keyEntryName) {
    return new Promise((resolve, reject) => {
        const transaction = db.transaction([STORE_NAME], 'readwrite');
        const store = transaction.objectStore(STORE_NAME);
        const request = store.put(key, keyEntryName);

        request.onsuccess = () => {
            resolve();
        };

        request.onerror = (event) => {
            reject(event.target.error);
        };
    });
}

async function deleteAllDeksExceptAnonymousDek(db) {
    const transaction = db.transaction([STORE_NAME], 'readwrite'); 
    const store = transaction.objectStore(STORE_NAME);

    const allKeysRequest = store.getAllKeys();
    await new Promise((success, error) => {
        allKeysRequest.onsuccess = function () { 
            const allKeys = allKeysRequest.result; 
            allKeys.forEach(function (key) { 
                if (key !== ANONYMOUS_KEY_NAME) { 
                    store.delete(key); 
                } 
            }); 
        };

        transaction.oncomplete = function () { 
            success();
        }; 
        
        transaction.onerror = function (event) { 
            error(event.target.error); 
        };
    });
}

/**
 * @returns {Promise<CryptoKey>}
 */
export async function smartUserKeyGetting() {
    const loginHelper = await import("../loginhelper.js");
    let authKey;
    const loginDetails = loginHelper.getLoginDetails();
    if(loginDetails instanceof loginHelper.LoginDetails) { //authenticated user!
        authKey = await getAuthenticatedUserDek(loginDetails.userId);
        if(!(authKey instanceof CryptoKey)) {
            console.warn('Authenticated user does not have a key');
            await loginHelper.logout();
            return;
        }

        return authKey;
    }

    let anonKey = await getAnonymousUserDek();
    if(!(anonKey instanceof CryptoKey)) {
        anonKey = await createAnonymousDek();
    }

    return anonKey;
}

/**
 * @param {Uint8Array} data 
 * @param {CryptoKey} key 
 * @returns {Promise<string>}
 */
export async function encrypt(data, key) {
    const cipherTextIv = await encryptToUint8Array(data, key);
    return btoa(String.fromCharCode(...cipherTextIv));
}

/**
 * @param {Uint8Array} data 
 * @param {CryptoKey} key 
 * @returns {Promise<Uint8Array>}
 */
export async function encryptToUint8Array(data, key) {
    if(!(key instanceof CryptoKey)) {
        throw new Error(`Key must be a cryptokey or authandanonkey, but is ${key.constructor.name}`);
    }

    let iv = new Uint8Array(12);
    iv = crypto.getRandomValues(iv);

    const keysArray = (key instanceof CryptoKey)? [key] : key.keyArray();
    if(!(keysArray?.[0] instanceof CryptoKey)) {
        throw new Error('First key in keyarray must be a cryptokey');
    }

    const cipherText =  await crypto.subtle.encrypt({
        name: 'AES-GCM',
        iv: iv,
        tagLength: 128
    }, keysArray[0], data);

    const cipherTextIv = new Uint8Array(iv.byteLength + cipherText.byteLength);
    cipherTextIv.set(iv, 0);
    cipherTextIv.set(new Uint8Array(cipherText), iv.byteLength);
    return cipherTextIv;
}

/**
 * @param {string} data 
 * @param {CryptoKey} key 
 * @returns {Promise<ArrayBuffer>}
 */
export async function decrypt(data, key) {
    if(typeof data !== 'string') {
        throw new Error('Data must be a string');
    } else if(!(key instanceof CryptoKey)) {
        throw new Error(`Key must be a cryptokey or authandanonkey, but is ${key.constructor.name}`);
    }

    const cipherTextIv = Uint8Array.from(atob(data), c => c.charCodeAt(0));
    return await decryptFromUint8Array(cipherTextIv, key);
}

/**
 * @param {Uint8Array} cipherTextIv 
 * @param {CryptoKey} key 
 * @returns {Promise<ArrayBuffer>}
 */
export async function decryptFromUint8Array(cipherTextIv, key) {
    if(!(key instanceof CryptoKey)) {
        throw new Error(`Key must be a cryptokey or authandanonkey, but is ${key.constructor.name}`);
    }

    const iv = cipherTextIv.subarray(0, 12);
    const cipherText = cipherTextIv.subarray(12);

    const keysArray = (key instanceof CryptoKey)? [key] : key.keyArray();

    for(const key of keysArray) {
        try {
            return await crypto.subtle.decrypt({
                name: 'AES-GCM',
                iv: iv,
                tagLength: 128
            }, key, cipherText);
        } catch { //invalid key, try next one!
            continue;
        }
    }
}