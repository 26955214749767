const lang = location.pathname.split('/')[1];

let jsonPromise;
export async function showErrorMessageForApiErrors(apiError) {
    if(apiError instanceof ApiError) {
        jsonPromise ??= await loadJson();

        const serviceMsgs = jsonPromise[apiError.serviceId];
        const errorMsg = serviceMsgs.errors?.[apiError.errorCode];
        
        const sjHelpers = await import('../js/helpers.js');
        if(typeof errorMsg === 'string') { //simple string, not specified title and text
            sjHelpers.showErrorMsg(serviceMsgs.defaultTitle, errorMsg);
        } else {
            sjHelpers.showErrorMsg(errorMsg?.title ?? serviceMsgs.defaultTitle, errorMsg?.text ?? serviceMsgs.defaultText);
        }
    }
}

/**
 * @param {object} response 
 * @param {string} serviceId
 * @returns {ApiError}
 */
export function apiErrorFromJson(json, serviceId) {
    return new ApiError(serviceId, (typeof json?.errorConstant === 'string')? json.errorConstant : 'ERROR');
}

async function loadJson() {
    let response = await fetch(`/content/shared/api/error-msgs/${lang}.json`);
    if(!response.ok) {
        console.warn(`No error messages found for lang ${lang}`);
        console.warn('Loading for lang=en');
        response = await fetch('/content/shared/api/error-msgs/en.json');
    }

    return await response.json();
}

export class ApiError extends Error {
    serviceId;
    errorCode;
    constructor(serviceId, errorCode) {
        super();
        this.serviceId = serviceId;
        this.errorCode = errorCode;
    }
}

export const AI_SERVICE_ID = 'AI_SERVICE';
export const LOGIN_SERVICE_ID = 'LOGIN_SERVICE';
export const CVSDATA_SERVICE_ID = 'CVSDATA_SERVICE';