import summary from "./summary.css" assert {type: 'css'};
import css from "./pricing-view.css" assert {type: 'css'};
import * as plansServiceApi from "../../shared/api/plansservice.js";
import * as apiModel from "../../shared/api/models.js";
import "../../../public/frontend/js/jquery-3.5.1.min.js";
import * as curr from "../../shared/js/currency_calculator.js";
import * as login from "../../shared/js/loginhelper.js";
import * as actC from "../../pages/checkout/activation/activation-constants.js";
import {AwaitableHTMLElement, connectedCallbackAwaitable} from "../../shared/js/waitable-component.js";
import {translatePlanCode} from "../../shared/js/translator.js";
import * as config from "../../../js/configmodule.js";

const lang = location.pathname.split('/')[1];
const html = fetch(`/content/views/pricing-view/${lang}/pricing-view.html`).then(x => x.text());

/**@type {{[planCode: string]: apiModel.PricingAndPlanDetails}} */
let planData;

const fetchPromise = (async () => {
    planData = await plansServiceApi.getPlansForUser(false);
})();

class PricingView extends AwaitableHTMLElement {
    #paywallPromise;
    #loginSignupView;
    #loadingPromise;

    constructor() {
        super();
        this.style.filter = 'blur(10px)';
        this.style.transition = 'filter .333s ease';
        this.attachShadow({mode: 'open'});

        this.#loadingPromise = html.then(c => {
            this.shadowRoot.adoptedStyleSheets ??= [];
            [summary, css].forEach(x => {
                if(x instanceof CSSStyleSheet) {
                    this.shadowRoot.adoptedStyleSheets = [...this.shadowRoot.adoptedStyleSheets, x];
                } else if(typeof x === 'function') {
                    this.shadowRoot.adoptedStyleSheets = [...this.shadowRoot.adoptedStyleSheets, x()];
                }
            });
    
            this.shadowRoot.innerHTML = c;
        });
    }

    async connectedCallback() {
        this.#loadingPromise
        .then(_ => fetchPromise)
        .then(async _ => {
            this.#fillPlanDetails();
            this.style.filter = null;

            return;
            if(login.isLoggedIn() && //load paywall if user is logged in only
                Object.values(planData).filter(x => x.allowedToUpgrade).length) { //and has at least one upgradable plan
                const observer = new IntersectionObserver(async (cb) => {
                    if(cb[0]?.isIntersecting) {
                        this.#paywallPromise ??= this.#initializePaywall();
    
                        observer.unobserve(this);
                        observer.disconnect();
                    }
                });
    
                observer.observe(this);
            }
        });
    }

    async #initializePaywall() {
        const paywall = await import("../paywall/paywall.js");
        const stripe = await import("../../components/checkout/stripe/stripe.js");
        return await paywall.initializePaywall(planData, new stripe.StripeCheckout());
    }

    #fillPlanDetails() {
        const $shadow = $(this.shadowRoot);
        const currency = curr.calculatePreferredCurrencyByTimezone();
        $shadow.find('[data-plancode]').each((_, x) => {
            const $planContainer = $(x);
            const planCode = $planContainer.attr('data-plancode');

            if(!planCode in planData) {
                $planContainer.remove();
                return;
            }

            /**@type {apiModel.PricingAndPlanDetails} */
            const details = planData[planCode];
            const price = details.getPriceForCurrency(currency);
            const planName = translatePlanCode(planCode);//planNames[planCode] ?? '';

            $planContainer.find('.plan-name').text(planName);
            $planContainer.find('.price').text(curr.calculatePriceStringForCurrency(price.priceCurrency, price.upgradePrice));

            const $features = $planContainer.find('ul');
            if(!details.coverLettersAllowed) {
                $features.find('li.cover-letter i').removeClass('ti-check text-success').addClass('ti-close text-danger');
            }

            if(details.planDurationInDays < 30.0) {
                $features.attr('data-time', 'daily');
                $features.find('li.access-time-days span').text((_, text) => text.replace('@time@', details.planDurationInDays.toString()));
            } else {
                $features.attr('data-time', 'monthly');
                $features.find('li.access-time-month span').text((_, text) => text.replace('@time@', Math.floor(details.planDurationInDays / 30.0)));
            }

            const $aiCredits = $features.find('li.ai-credits span');
            $aiCredits.text($aiCredits.text().replace('%d', details.aiCredits ?? 0));

            if(details.allowedToUpgrade) {
                $planContainer.find('button[disabled]').remove();
                const self = this;
                $planContainer.find('button').on('click', function() {
                    self.#triggerPaywall($(this), planCode);
                });
            } else {
                $planContainer.find('button:not([disabled])').remove();
            }
        });
    }

    /**
     * @param {JQuery<HTMLButtonElement>} $btn
     * @param {string} planCode
     */
    async #triggerPaywall($btn, planCode) {
        if(!login.isLoggedIn()) {
            await this.#showLoginSignupView($btn, planCode);
            return;
        }

        $btn.prop('disabled', true);
        try {
            //this.#paywallPromise ??= this.#initializePaywall();

            //const paywall = await this.#paywallPromise;
            //paywall.preselectPlan(planCode);

            sessionStorage.setItem(config.ACTIVATION_PLAN_PRICING_DETAILS, JSON.stringify(planData));
            actC.setPreSelectedPlanCode(planCode);
            actC.setUpgradeMode(actC.JUST_UPGRADE_MODE);
            location.href = `${location.origin}/${lang}/checkout/select-plan.html`;
        } finally {
            $btn.prop('disabled', false);
        }
    }

    /**
     * @param {JQuery<HTMLButtonElement>} $btn 
     * @param {string} planCode
     */
    async #showLoginSignupView($btn, planCode) {
        const mod = await import("../../views/login-signup/login-signup.js");
        this.#loginSignupView = await mod.injectLoginSignupModal(this.shadowRoot, () => this.#triggerPaywall($btn, planCode));
        await this.#loginSignupView.prefillSignupDataFromLocalCache();
        this.#loginSignupView.showModal();
    }
}

connectedCallbackAwaitable(PricingView);
customElements.define('pricing-view', PricingView);

export {};