import "../../../public/frontend/js/jquery-3.5.1.min.js";
import "../../../js/configmodule.js";
import {deriveKekAndPasswordHash, unwrapDekAndStore} from "./storage/keys.js";
import {getHashingParameters, login as loginRequest, AccessToken, refreshLogin} from "../api/loginservice.js";

export const LOCAL_STORAGE_TOKEN = '_local_Storage_Token';

const $ajax = $.ajax;
$.ajax = async function() {
	if(typeof arguments?.[0]?.isRefreshTokenAware === 'boolean' && !!arguments[0].isRefreshTokenAware) {
		await refreshLoginDetailsIfRequired();
	}
	
	return await $ajax.apply($, arguments);
}

$(document).on( "ajaxError", function(event, jqXHR) {
	if((jqXHR.status === 401 && jqXHR.responseJSON?.errorConstant === 'INVALID_OR_EXPIRED_TOKEN') 
		|| jqXHR.responseJSON?.errorConstant === 'NOT_ENOUGH_PERMISSIONS') {
		localStorage.removeItem(LOCAL_STORAGE_TOKEN);
		console.log('Token invalid, force logging out');
		logout();
	}
});

$.ajaxSetup({
	beforeSend: function(jqXHR, settings) {
		const details = getLoginDetails();
		const accessToken = details?.accessToken;
		if(accessToken?.length) {
			jqXHR.setRequestHeader('JwtToken', `Bearer ${accessToken}`);
			settings.xhrFields = {
				withCredentials: true
			};
		}
	}
});

let refreshTokenPromise;

/**
 * @returns {Promise<void>}
 */
async function refreshLoginDetailsIfRequired() {
	const loginDetails = getLoginDetails();
	if(!(loginDetails instanceof LoginDetails)) {
		return;
	}

	if(new Date().getTime() >= loginDetails.expiry.getTime()) {
		refreshTokenPromise ??= new Promise(async (success, error) => {
			try {
				const refreshedToken = await refreshLogin();
				saveAccessTokenToLocalStorage(refreshedToken, loginDetails.userEmail);
				success();
			} catch {
				await logout();
			}
		});

		await refreshTokenPromise;
	}
}

export async function login(email, password) {
	if(!typeof email === 'string' || !email.length
		|| !typeof password === 'string' || !password.length) {
		throw new Error('Credentials must be set');
	}

	const hashParams = await getHashingParameters(email);
	const derivation = await deriveKekAndPasswordHash(email, password, hashParams?.argon2Params);
	const accessToken = await loginRequest(email, derivation.lsPassword);
	await saveLoginDetails(accessToken, email, derivation.kek);
	return accessToken?.userId;
}

export function isLoggedIn() {
	return !!getLoginDetails();
}

/**
 * @returns {LoginDetails | undefined}
 */
export function getLoginDetails() {
	try {
		const json = JSON.parse(localStorage.getItem(LOCAL_STORAGE_TOKEN)) || undefined;
		return (typeof json === 'object')? LoginDetails.fromJson(json) : undefined;
	} catch {
		return undefined;
	}
}

/**
 * @param {AccessToken} accessToken 
 * @param {string} email 
 * @param {CryptoKey} kek 
 */
export async function saveLoginDetails(accessToken, email, kek) {
	if(!(accessToken instanceof AccessToken)) {
		throw new Error('Access token has invalid format');
	} else if(typeof email !== 'string' || !email.length) {
		throw new Error('Email must not be null');
	} else if(!(kek instanceof CryptoKey)) {
		throw new Error('Kek must be a cryptokey');
	}

	saveAccessTokenToLocalStorage(accessToken, email);
	await unwrapDekAndStore(accessToken.key, kek, accessToken.userId);
}

/**
 * @param {AccessToken} accessToken 
 */
function saveAccessTokenToLocalStorage(accessToken, email) {
	const expiry = new Date();
	expiry.setSeconds(expiry.getSeconds() + accessToken.expires_in);
	localStorage.setItem(LOCAL_STORAGE_TOKEN, JSON.stringify({
		accessToken: accessToken.access_token, 
		userName: accessToken.userName,
		userId: accessToken.userId,
		userEmail: email,
		expiry: expiry.toISOString()
	}));
}

export async function logout() {
	localStorage.removeItem(LOCAL_STORAGE_TOKEN);
	redirectToLoginPage();
}

export function redirectToLoginPage() {
	location.replace(`/${location.pathname.split('/')[1]}/login.html`);
}

export class LoginDetails {

	/**@type {string} */
	userName;

	/**@type {string} */
	userId;

	/**@type {string} */
	userEmail;

	/**@type {string} */
	accessToken;

	/**@type {Date} */
	expiry;

	static fromJson(json) {
		const loginDetails = new LoginDetails();
		loginDetails.userName = json.userName;
		loginDetails.userId = json.userId;
		loginDetails.userEmail = json.userEmail;
		loginDetails.accessToken = json.accessToken;
		loginDetails.expiry = new Date(json.expiry);

		return loginDetails;
	}

	/**
	 * @returns {{firstName: string | undefined, lastName: string | undefined}}
	 */
	tryGetFirstAndLastName() {
		const split = this.userName?.split(' ').filter(x => x.length);
		return {
			firstName: split?.[0] ?? '',
			lastName: split?.slice(1)?.join(' ') ?? ''
		};
	}
}