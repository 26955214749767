import {createModalDialog, Modal} from "../modal/modal.js";
import "../../../public/frontend/js/jquery-3.5.1.min.js";
import {isLoggedIn} from "../../shared/js/loginhelper.js";

let isReminderActive = false;
const SIGNUP_REMINGER_SHOWN_CACHE = '_signup_reminder_shown';

let $dialog;

/**@type {import('../signup/signup').SignupView | undefined} */
let signupView;

/**@type {Modal | undefined} */
let modal;

export async function activateReminder() {
    if(isReminderActive) {
        return;
    }

    isReminderActive = true;
    if(isLoggedIn()) {
        return;
    }

    await import("../signup/signup.js");

    const lang = location.pathname.split('/')[1];
    await fetch(`/content/views/signup-reminder/${lang}/signup-reminder.html`)
        .then(x => x.text())
        .then(html => {
            $dialog = $(html);
            signupView = $dialog.find('signup-view').get(0);
        
            modal = createModalDialog({
                modalBody: $dialog,
                modalDialogClassList: 'modal-xl modal-dialog-centered modal-dialog-scrollable',
                onHide: () => modal.detachFromBody()
            });
            
            document.addEventListener("visibilitychange", (event) => {
                if(document.visibilityState === "hidden") {
                    displaySignupReminder();  
                }
            });
        
            document.addEventListener('mouseleave', () => displaySignupReminder());
        });
}

async function displaySignupReminder() {
    if(!localStorage.getItem(SIGNUP_REMINGER_SHOWN_CACHE)) {
        localStorage.setItem(SIGNUP_REMINGER_SHOWN_CACHE, 'true');

        modal?.attachToBody();
        await signupView?.prefillSignupDataFromCVLocalCache?.();
        await modal?.showModal();
    }
}