import * as login from "../../shared/js/loginhelper.js";
import style from "./header.css" assert {type: 'css'};
import "../../../public/frontend/js/jquery-3.5.1.min.js";
import * as config from "../../../js/configmodule.js";
import {calculateInitialsData} from "../../shared/js/helpers.js";
import {AwaitableHTMLElement, connectedCallbackAwaitable} from "../../shared/js/waitable-component.js";

const logoUrl = new URL('../../../public/logo/cvcollage.svg', import.meta.url);

jQuery.event.special.touchstart = {
    setup: function( _, ns, handle ) {
        this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
jQuery.event.special.touchmove = {
    setup: function( _, ns, handle ) {
        this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
    }
};

jQuery.event.special.touchend = {
    setup: function( _, ns, handle ) {
        this.addEventListener("touchend", handle, { passive: !ns.includes("noPreventDefault") });
    }
};

$(`
    <style>
    @media(max-width: 992px) {
        body.menu-open {
          overflow: hidden !important;
        }
      }
    </style>
`).appendTo('head');

const lang = location.pathname.split('/')[1];
const html = fetch(`/content/components/header/${lang}/header.html`).then(x => x.text())
    .then(x => x.replace(/{{lang}}/g, lang.toUpperCase()));

if(document.readyState === "complete" || document.readyState === "loaded" || document.readyState === "interactive") {
    document.body.classList.add('is-document-loaded');
} else {
    document.addEventListener('DOMContentLoaded', () => document.body.classList.add('is-document-loaded'));
}

/**
 * 
 * @description Function to prevent fouc
 * @param {Function} callback The callback to execute after loading completed
 * @deprecated
 * @returns 
 */
export async function waitUntilComponentLoad(callback) {
    if(arguments.length <= 1) {
        return;
    }

    const promises = [...arguments].slice(1).map(x => {
        return new Promise((success, error) => {
            if(x instanceof HTMLElement) {
                $(x).ready(() => success());
            } else if(x instanceof jQuery) {
                x.ready(() => success());
            } else {
                error('Must be a html element or jquery object');
            }
        });
    });

    await Promise.all(promises);
    callback?.();
}



export class SponJobsHeader extends AwaitableHTMLElement {
    #$mobileMenu;
    #$aiCredits;
    #touchCoordinates;
    #loadingPromise;
    #$activeDocumentSaveLabel;
    #isEnteredActiveDocumentSavingMode = false;

    constructor() {
        super();
        this.attachShadow({mode: 'open'});
        this.#loadingPromise = html.then(x => {
            this.shadowRoot.innerHTML = x;
            this.#touchCoordinates = false;
    
            if(typeof style === 'function') {
                this.shadowRoot.adoptedStyleSheets = [style()];
            }else if(style instanceof CSSStyleSheet) {
                this.shadowRoot.adoptedStyleSheets = [style];
            }
        });
    }

    async connectedCallback() {
        await this.#loadingPromise;
        const $shadow = $(this.shadowRoot);
        this.#$mobileMenu = $shadow.find('.site-mobile-menu');
        this.#$aiCredits = $shadow.find('.ai-credits');

        $shadow.find('.js-menu-toggle').on('click', (event) => {
            event.preventDefault();
            this.#$mobileMenu.toggleClass('is-visible');
            $('body').toggleClass('menu-open');
        });

        $(document).on('mouseup', (e) => {
            if($(document.body).hasClass('menu-open') && e.clientX > this.#$mobileMenu.width()) {
                this.#hideMobileMenu();
            }
		});

        await this.updateLoginStatus();
		
		//language redirections:
        //safari bug: 16.3 does not support lookbehinds
        $shadow.find('a[data-lang] + ul a[data-lang]').each((id, x) => x.href = `${location.origin}${location.pathname.replace(/^\/[a-z]{2}/, '/' + x.getAttribute('data-lang').toLowerCase())}`);

        const self = this;
        $shadow.find('[data-action]').on('click', function(event) {
            switch($(this).attr('data-action')) {
                case 'logout':
                    event.preventDefault();
                    login.logout();
                break;
                case 'login':
                case 'sign-up':
                    sessionStorage.setItem(config.LOGIN_SIGNUP_REDIRECT_URL, location.href); //later redirect to current page!
                break;
                case 'live-chat':
                    self.#liveChatBtnClicked(event);
                break;
            }
        });

        this.#$mobileMenu.on('touchstart', (event) => {
                const touch = event.touches[0] || event.changedTouches[0];
                this.#touchCoordinates = {x: touch.pageX, y: touch.pageY};
            })
            /* .on('touchmove', (event) => {
                if(this.#touchCoordinates) {
                    const touch = event.touches[0] || event.changedTouches[0];
                    this.#$mobileMenu.css('transform', `translateX(${touch.pageX - this.#touchCoordinates.x}px)`);
                }
            }) */
            .on('touchend', (event) => {
                if(this.#touchCoordinates) {
                    if(this.#touchConditionFulfilled(event)) {
                        this.#hideMobileMenu();
                    }
                }

                this.#touchCoordinates = undefined;
            });
        
        $shadow.find('[data-toggle="collapse"][data-target]').on('click', function(event) {
            event.stopPropagation();
            event.preventDefault();
            $shadow.find($(this).attr('data-target')).toggleClass('show');
            $(this).find('.arrow-collapse').addBack('.arrow-collapse').toggleClass('active');
        });

        //mark current page as active in menu
        this.#$mobileMenu.find(`a[href*="${location.href.split("/").pop()}"]`).closest('li').addClass('active');
        $shadow.find('a.nav-link[href="#"]').on('click', (event) => event.preventDefault()); //prevents scrolling upwards when clicking on tools or about us
    }

    async updateLoginStatus() {
        const loginDetails = login.getLoginDetails();
        const $shadow = $(this.shadowRoot);
        $shadow.find('img').attr('src', logoUrl);
        if(loginDetails instanceof login.LoginDetails) {
            $shadow.children().attr('data-loggedin', 'true');
            $shadow.find('.user-name').html(loginDetails.userName);
            $shadow.find('.user-email').html(loginDetails.userEmail);

            const {firstName, lastName} = loginDetails.tryGetFirstAndLastName();
            const initialsData = calculateInitialsData(firstName, lastName);

            const $avatar = $shadow.find('.avatar');
            if(initialsData?.initials?.length) {
                $avatar.css('background-color', initialsData.initialBgcColor)
                    .find('h4').text(initialsData.initials).css('color', initialsData.initialTxtColor);
            }

            if(this.hasAttribute('active-document')) {
                await this.#updateActiveDocumentDetails(); //active document mode
            }

            import('../../shared/api/plansservice.js')
                .then(plans => plans.getAiCreditsForUser())
                .then(credits => this.updateAiCredits(credits))
                .catch(_ => console.error('Could not fetch ai credits'));
        } else {
            $shadow.children().attr('data-loggedin', 'false');
        }
    }

    updateAiCredits(creditsLeft) {
        if(typeof creditsLeft !== 'number' || isNaN(creditsLeft)) {
            console.error(`${creditsLeft} is not a number`);
            return;
        }

        this.#$aiCredits.removeClass('loading loaded');
        setTimeout(() => {
            this.#$aiCredits.text(creditsLeft.toFixed(!!(creditsLeft % 1)? 2 : 0));
            this.#$aiCredits.addClass('loaded');
        }, 250);
    }

    async #updateActiveDocumentDetails() {
        const cvStore = await import("../../shared/js/cv-storage.js");
        const currentMetadata = await cvStore.getPrivateMetadata();
        
        const $activeDoc = $(this.shadowRoot).find('.active-document-name');
        $activeDoc.text(currentMetadata?.documentName ?? $activeDoc.get(0).getAttribute('data-new-document'));
        this.#$activeDocumentSaveLabel = $(this.shadowRoot).find('[data-mode="active-document"] .save');
    }

    /**
     * @returns {Promise<void>}
     */
    enterActiveDocumentSaveMode() {
        return new Promise((success, error) => {
            if(this.hasAttribute('active-document') && this.#$activeDocumentSaveLabel
                && !this.#isEnteredActiveDocumentSavingMode) {
                this.#$activeDocumentSaveLabel.siblings().removeClass('show');
                setTimeout(() => {
                    this.#$activeDocumentSaveLabel.addClass('show');
                    this.#isEnteredActiveDocumentSavingMode = true;
                    success();
                }, 150);
            } else {
                success();
            }
        });
    }

    exitActiveDocumentSaveMode() {
        if(this.hasAttribute('active-document') && this.#$activeDocumentSaveLabel
            && this.#isEnteredActiveDocumentSavingMode) {
            this.#$activeDocumentSaveLabel.removeClass('show');
            setTimeout(() => {
                this.#$activeDocumentSaveLabel.siblings().addClass('show');
                this.#isEnteredActiveDocumentSavingMode = false;
            }, 150);
        }
    }

    #liveChatBtnClicked(event) {
        event.preventDefault();
        const $this = $(event.target);
        if($this.hasClass('loading')) {
            return;
        }

        $this.addClass('loading');
        import("../brevochat/brevochat.js").then(brevo => {
            brevo.toggleBrevoChat();
            $this.removeClass('loading');
        });
    }

    #touchConditionFulfilled(event) {
        if(!this.#touchCoordinates) {
            return false;
        }

        const touch = event.touches[0] || event.changedTouches[0];
        return touch.pageX < this.#touchCoordinates.x 
            && Math.abs(touch.pageX - this.#touchCoordinates.x) > 30.0
            && Math.abs(touch.pageY - this.#touchCoordinates.y) < 15.0;
    }

    #hideMobileMenu() {
        this.#$mobileMenu.removeClass('is-visible').css('transform', '');
        $('body').removeClass('menu-open');
    }
}

connectedCallbackAwaitable(SponJobsHeader);
customElements.define('spon-header', SponJobsHeader);