export const STATIC_ASSET_URL = 'https://designs.cvcollage.com';
export const apiBaseUrl = 'https://api.cvcollage.com/v1';

export const ACTIVATION_CVDATA_CACHE = 'ACTIVATION_CVDATA_CACHE';
export const ACTIVATION_TEMPLATE_CODE_CACHE = 'ACTIVATION_TEMPLATE_CODE_CACHE';
export const ACTIVATION_CV_LANGUAGE_CACHE = 'ACTIVATION_CV_LANGUAGE_CACHE';
export const ACTIVATION_DOC_TYPE = 'ACTIVATION_DOC_TYPE';
export const ACTIVATION_PLAN_PRICING_DETAILS = 'ACTIVATION_PLAN_PRICING_DETAILS';

export const LOGIN_SIGNUP_REDIRECT_URL = 'loginSignupRedirect';

export const EXPERIMENTAL_MODE_PARAM = 'experimentalMode';

export const ONBOARDING_COMPLETED_CACHE = '__onboarding_completed_cache';

export const SIGNUP_SEGMENT_CACHE = 'signupSegment';

window.apiBaseURL=apiBaseUrl;

window.COUPON_CACHE="couponCache";

//cache variables for activations page
window.ACTIVATION_CVDATA_CACHE = "ACTIVATION_CVDATA_CACHE";
window.ACTIVATION_TEMPLATE_CODE_CACHE = "ACTIVATION_TEMPLATE_CODE_CACHE";
window.ACTIVATION_CV_LANGUAGE_CACHE = "ACTIVATION_CV_LANGUAGE_CACHE";