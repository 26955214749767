import {apiBaseUrl} from "../../../js/configmodule.js";
import "../../../public/frontend/js/jquery-3.5.1.min.js";
import error from "./loginservice-errors.json" assert {type: 'json'};
import {ApiError, LOGIN_SERVICE_ID, apiErrorFromJson} from "./error-msg.js";

const lang = location.pathname.split('/')[1];

/**
 * @param {string} newPassword 
 * @param {string} oldPassword
 * @returns {Promise<void>}
 */
export function changePassword(oldPassword, newPassword) {
    return new Promise((success, error) => {
        $.ajax({
            url: `${apiBaseUrl}/login/recover/changepassword?language=${encodeURIComponent(lang)}`,
            method: 'POST',
            timeout: 30000,
            xhrFields: {withCredentials: true},
            contentType: 'application/json',
            data: JSON.stringify({oldpassword: oldPassword, newpassword: newPassword}),
            success: (_) => success(),
            error: (jqXHR) => {
                if(typeof jqXHR?.responseJSON?.errorConstant === 'string') {
                    error(jqXHR.responseJSON.errorConstant);
                } else {
                    error(jqXHR.responseJSON);
                }
            }
        });
    });
}

/**
 * @param {RegistrationRequest} request 
 * @param {string | null} lang 
 * @param {boolean} sendPasswordEmail
 * @returns {Promise<[AccessToken, HashingParams]>}
 */
export async function registerNewUser(request, lang, sendPasswordEmail = false) {
    let response;
    try {
        response = await fetch(`${apiBaseUrl}/login/newuser/register?language=${lang}&sendPasswordEmail=${sendPasswordEmail ?? false}`, {
            body: JSON.stringify(request),
            method: 'POST',
            credentials: 'include',
            headers: {'Content-Type': 'application/json'}
        });

        if(!response.ok) {
            const json = await response.json();
            console.log(json);
            throw apiErrorFromJson(json, LOGIN_SERVICE_ID);
        }

        return await readAccessTokenAndHashingParamsFromXhr(response);
    } catch(error) {
        console.error(error);
        if(error instanceof ApiError) {
            throw error; //propagate!
        } else {
            throw new ApiError(LOGIN_SERVICE_ID, 'ERROR');
        }
    }
}

/**
 * @param {Response} response 
 * @returns {Promise<[AccessToken, HashingParams]>}
 */
async function readAccessTokenAndHashingParamsFromXhr(response) {
    const accessToken = new AccessToken();
    Object.assign(accessToken, await response.json());

    let hashingParam;
    const hashingParamsHeader = response.headers?.get('x-sponjobs-data');
    if(!hashingParamsHeader?.length) {
        return [accessToken, null];
    }

    try {
        hashingParam = HashingParams.fromJson(JSON.parse(hashingParamsHeader));
        return [accessToken, hashingParam];
    } catch(error) {
        console.error(error);
        return [accessToken, null];
    }
}

/**
 * @param {string} email 
 * @param {string} lsPassword 
 * @returns {Promise<AccessToken>}
 */
export async function login(email, lsPassword) {
    try {
        const response = await $.ajax({
            method: 'POST',
            mimeType: 'application/json',
            timeout: 30000,
            url: `${apiBaseUrl}/login/token`,
            data: `grant_type=password&username=${email}&password=${encodeURIComponent(lsPassword)}`,
            xhrFields: {
                withCredentials: true
           }
        });

        const accessToken = new AccessToken();
        Object.assign(accessToken, response);
        return accessToken;
    } catch(jqXHR) {
        if(jqXHR.status === 400 && jqXHR.responseJSON?.error === 'invalid_grant') {
            throw new ApiError(LOGIN_SERVICE_ID, 'WRONG_CREDENTIALS');
        } else {
            throw new ApiError(LOGIN_SERVICE_ID, 'GENERAL_ERROR');
        }
    }
}

/**
 * @returns {Promise<AccessToken>}
 */
export async function refreshLogin() {
    try {
        const param = new URLSearchParams();
        param.append('grant_type', 'refresh_token');

        const response = await fetch(`${apiBaseUrl}/login/token`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            credentials: 'include',
            body: param
        });

        if(!response?.ok) {
            throw new RefreshTokenExpiredError();
        }
        
        const json = await response.json();

        const accessToken = new AccessToken();
        Object.assign(accessToken, json);
        return accessToken;
    } catch(error) {
        if(error instanceof RefreshTokenExpiredError) {
            throw error; //propagate
        } else {
            throw new RefreshTokenExpiredError();
        }
    }
}

/**
 * @param {string} email 
 * @returns {Promise<HashingParams | null>}
 */
export async function getHashingParameters(email) {
    if(typeof email !== 'string' || !email.length) {
        throw new Error('Email cannot be empty');
    }

    try {
        const json = await $.ajax({
            url: `${apiBaseUrl}/login/hash?email=${encodeURIComponent(email)}`,
            method: 'GET',
            timeout: 30000,
            xhrFields: {
                withCredentials: true
            }
        });

        return HashingParams.fromJson(json);
    } catch(jqXHR) {
        const errorConst = (typeof jqXHR?.responseJSON?.errorConstant === 'string')? jqXHR?.responseJSON?.errorConstant: 'ERROR';
        throw new ApiError(LOGIN_SERVICE_ID, errorConst);
    }
}

/**
 * @deprecated
 * @param {*} errorCode 
 * @returns 
 */
export function translateErrorCode(errorCode) {
    errorCode ||= 'GENERAL_ERROR';
    const translations = error[lang] ?? error['en'] ?? Object.values(error)[0];
    return translations?.[errorCode] ?? translations?.['GENERAL_ERROR'] ?? 'An error has happened';
}

export class RegistrationRequest {
    /**@type {string} */
    firstName;

    /**@type {string} */
    lastName;

    /**@type {string} */
    password;

    /**@type {string} */
    email;

    /**@type {string} */
    captchaToken;

    /**@type {EmailMarketingDetails | undefined} */
    emailMarketing;

    /**@type {string | undefined} */
    anonymousDek;
}

export class EmailMarketingDetails {
    /**@type {"CV", "COVER", "RESIGNATION"} */
    signupSegment;

    /**@type {string | undefined} */
    ianaTimezone;
}

export class HashingParams {
    /**@type {'ARGON2I' | 'ARGON2D' | 'ARGON2ID'} */
    algorithm;

    /**@type {Argon2Params | null} */
    argon2Params;

    static fromJson(json) {
        if(typeof json === 'object') {
            const result = new HashingParams();
            result.algorithm = json.algorithm;
            result.argon2Params = Argon2Params.fromJson(json.argon2Params);
            return result;
        } else {
            return null;
        }
    }
}

export class Argon2Params {
    /**@type {number} */
    paralellism;

    /**@type {number} */
    memoryInKb;

    /**@type {number} */
    iterations;

    /**@type {'ARGON2I' | 'ARGON2D' | 'ARGON2ID'} */
    type;

    static fromJson(json) {
        if(typeof json === 'object') {
            const result = new Argon2Params();
            Object.assign(result, json);
            return result;
        } else {
            return null;
        }
    }
}

export class AccessToken {
    /**@type {string} */
    access_token;

    /**@type {string} */
    userName;

    /**@type {string} */
    userId;

    /**@type {string} */
    key;

    /**@type {number} */
    expires_in;

    /**@type {string[]} */
    authorities;
}

export class RefreshTokenExpiredError extends Error {

}