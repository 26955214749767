import * as apiModel from "./models.js";
import "../../../public/frontend/js/jquery-3.5.1.min.js";
import * as config from "../../../js/configmodule.js";

/**
 * @returns {Promise<{[planCode: string]: apiModel.PricingAndPlanDetails}>}
 */
export async function getPlansForUser(showOnlyAllowedPlans = false) {
    const response = await $.ajax({
        url: `${config.apiBaseUrl}/pricing`,
        method: 'GET',
        contentType: 'application/json',
        async: true,
        timeout: 30000,
        xhrFields: {withCredentials: true},
        isRefreshTokenAware: true
    });

    Object.keys(response).forEach(x => {
        const details = apiModel.PricingAndPlanDetails.fromJson(response[x]);
        if(showOnlyAllowedPlans && !details.allowedToUpgrade) {
            delete response[x];
        } else {
            response[x] = details;
        }
    });

    return response;
}

/**
 * @returns {Promise<number>}
 */
export async function getAiCreditsForUser() {
    const response = await $.ajax({
        url: `${config.apiBaseUrl}/aicredits`,
        method: 'GET',
        xhrFields: {withCredentials: true},
        timeout: 30000,
        contentType: 'text/plain',
        isRefreshTokenAware: true
    });

    return parseFloat(response);
}