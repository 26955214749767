import "../../../public/frontend/js/jquery-3.5.1.min.js";

const html = `
<div class="fade modal" style="display: none;" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
    <div class="modal-content">
        <div class="modal-header">
            <a role="button" href="#" class="text-white close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </a>
        </div>
        <div class="modal-body">
            
        </div>
    </div>
    </div>
</div>`

/**@type {Modal | null} */
let openModal;

const $backdrop = $('<div />', {class: 'modal-backdrop fade'});

/**@type {Set<Modal>} */
const attachedModals = new Set();

/**
 * 
 * @param {{modalBody?: jQuery | HTMLElement, 
 *  onHide?: (modal: Modal) => void, 
 *  modalDialogClassList?: string, 
 *  modalDialogCss?: string, 
 *  modalContentClassList?: string,
 *  modalBodyClassesList?: string,
 *  modalHeaderClassList?: string,
 *  modalFooterClassList?: string,
 *  modalFooter?: jQuery | HTMLElement}} options 
 * @returns {Modal}
 */
export function createModalDialog(options) {
    const modalDialogClassList = options.modalDialogClassList ?? 'modal-dialog-centered modal-dialog-scrollable';
    const modalContentClassList = options.modalContentClassList ?? 'bg-transparent border-0';
    const modalBodyClassesList = options.modalBodyClassesList ?? 'p-0 bg-white border';
    const modalHeaderClassList = options.modalHeaderClassList ?? 'bg-transparent d-md-none';
    const $modal = $(html);

    if(modalDialogClassList.length) {
        $modal.find('.modal-dialog').addClass(modalDialogClassList).attr('style', options.modalDialogCss);
    }

    if(modalContentClassList.length) {
        $modal.find('.modal-content').addClass(modalContentClassList);
    }

    if(modalBodyClassesList.length) {
        $modal.find('.modal-body').addClass(modalBodyClassesList);
    }

    if(modalHeaderClassList.length) {
        $modal.find('.modal-header').addClass(modalHeaderClassList);
    }
    
    const modal = new Modal($modal);
    modal.onHide = options.onHide;

    $modal.find('.modal-content').on('click mousedown mouseup', (event) => event.stopPropagation());
    $modal.find('a[data-dismiss="modal"]').on('click', function(event) {
        event.preventDefault();
        modal.hideModal();
    });

    if(options.modalBody instanceof jQuery) {
        options.modalBody.appendTo($modal.find('.modal-body'));
    } else if(options.modalBody instanceof HTMLElement) {
        $(options.modalBody).appendTo($modal.find('.modal-body'));
    }

    if(!!options.modalFooter) {
        const $footer = $('<div />', {class: 'modal-footer'});
        $footer.insertAfter($modal.find('.modal-body'));
        if(options.modalFooter instanceof jQuery) {
            options.modalFooter.appendTo($footer);
        } else if(options.modalFooter instanceof HTMLElement) {
            $(options.modalFooter).appendTo($footer);
        }
    }

    if(!!options.modalFooterClassList?.length) {
        $modal.find('.modal-footer').addClass(options.modalFooterClassList);
    }

    return modal;
}

export class Modal {
    #keyPressEventHandle;

    /**
     * @param {jQuery} $modal 
     */
    constructor($modal) {
        /**@type {jQuery} */
        this.$modal = $modal;

        /**@type {(modal: Modal) => void} */
        this.onHide = null;

        this.#keyPressEventHandle = (event) => this.#keyPressEventHandler(event);
        this.$modal.on('mousedown', (event) => this.hideModal());
        this.$modal.find('.modal-body').on('click', (event) => event.stopPropagation());
    }

    /**
     * @param {KeyboardEvent} event 
     */
    #keyPressEventHandler(event) {
        if(event.key === 'Escape' || event.key === 'Esc') {
            this.hideModal();
        }
    }

    attachToBody($el) {
        if(this.$modal.closest(document).length) {
            return;
        }

        this.$modal.appendTo($el ?? $('body'));
        attachedModals.add(this);
        
    }

    detachFromBody() {
        this.$modal.remove();
        attachedModals.delete(this);
        if(!attachedModals.size) {
            $backdrop.remove();
        }
    }

    async showModal() {
        if(! $backdrop.closest(document).length) {
            this.$modal.after($backdrop);
        }
        
        $backdrop.addClass('show');
        const promise = new Promise((success, _) => setTimeout(() => {
            this.$modal.show();
            setTimeout(() => {
                this.$modal.addClass('show');
                success();
            }, 150);
        }, 150));

        $(document).on('keydown', this.#keyPressEventHandle);
        $('body').addClass('modal-open');
        if(openModal instanceof Modal) {
            //openModal.hideModal();
        }

        openModal = this;
        await promise;
    }

    async hideModal() {
        this.$modal.removeClass('show');
        const promise = new Promise((success, _) => {
            setTimeout(() => {
                this.$modal.hide();
                $backdrop.removeClass('show');
                success();
                setTimeout(() => {
                    $backdrop.remove();
                    this.onHide?.(this);
                }, 150);
            }, 150);
        });

        $(document).off('keydown', this.#keyPressEventHandle);
        $('body').removeClass('modal-open');
        openModal = undefined;
        await promise;
    }
}